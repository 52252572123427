var $body,
    $contactPanelButton,
    $header,
    $mobileMenu,
    $menuSubItem,
    $mobileSubMenu,
    $mobileSubSubMenu,
    $mobileSubMenuToggle,
    $mobileSubSubMenuToggle,
    $sideMenu,
    $subSubMenu,
    $toggleMenu,
    $toggleSideMenu;

var didScroll;


$(document).ready(function($) {

    $body                       = $('body');
    $contactPanelButton         = $('.js-contact-panel-btn');
    $header                     = $('.js-header');
    $mobileMenu                 = $('.js-mobile-menu');
    $menuSubItem                = $('.js-menu-sub-item');
    $mobileSubMenu              = $('.js-mobile-sub-menu');
    $mobileSubSubMenu           = $('.js-mobile-sub-sub-menu');
    $mobileSubMenuToggle        = $('.js-mobile-sub-menu-toggle');
    $mobileSubSubMenuToggle     = $('.js-mobile-sub-sub-menu-toggle');
    $sideMenu                   = $('.js-side-menu-show-more-items');
    $subSubMenu                 = $('.js-sub-sub-menu');
    $toggleMenu                 = $('.js-toggle-menu');
    $toggleSideMenu             = $('.js-side-menu-show-more-toggle');



    $(window).on('resize load', function() {
        if (!isMobile()) {
            if ($toggleSideMenu.hasClass('active')) {
                $toggleSideMenu.removeClass('active');
                $sideMenu.stop().slideUp();
            }
        }
    });

    $(window).on('scroll', function() {
        didScroll = true;
    });

    setInterval(function() {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    function hasScrolled() {
        if ($(window).scrollTop() > $header.outerHeight()) {
            $header.addClass("is-scrolled");
        } else {
            $header.removeClass("is-scrolled");
        }
    }

    $menuSubItem.hover(function() {
        var subMenuID = $(this).data('sub-menu-item-id');
        var $activeSubSubMenu = $('[data-sub-sub-menu-id="' + subMenuID + '"]');

        if (!isMobile() && !$(this).hasClass('c-menu__sub-item--active')) {
            $menuSubItem.removeClass('c-menu__sub-item--active');
            $(this).addClass('c-menu__sub-item--active');
        }

        if (!isMobile() && !$activeSubSubMenu.hasClass('c-menu__sub-sub-menu--active')) {
            $subSubMenu.removeClass('c-menu__sub-sub-menu--active');
            $activeSubSubMenu.addClass('c-menu__sub-sub-menu--active');
        }
    });

    $toggleMenu.on('click', function (e) {
        e.preventDefault(); // Prevents scrolling to top

        if ($(this).hasClass('is-open')) {
            $(this).removeClass('is-open');
            $body.removeClass('mob-menu-is-open');
            $mobileMenu.removeClass('is-open');
        } else {
            $(this).addClass('is-open');
            $body.addClass('mob-menu-is-open');
            $mobileMenu.addClass('is-open');
        }
    });

    $mobileSubMenu.slideUp(); // Close all submenus on first load

    $mobileSubMenuToggle.on('click', function() {
        $mobileSubMenu.stop().slideUp();
        var $targetMenuItem = $(this).closest('.js-mobile-menu-item');
        var $targetSubMenu = $targetMenuItem.find('.js-mobile-sub-menu');
        var activeClassName = 'is-open';

        if ($(this).hasClass(activeClassName)) { // If clicked submenu is active
            $mobileSubMenuToggle.removeClass(activeClassName);
            $targetSubMenu.stop().slideUp();
        } else { // If clicked submenu is not active
            $mobileSubMenuToggle.removeClass(activeClassName);
            $(this).addClass(activeClassName);
            $targetSubMenu.stop().slideDown();
        }
    });

    $mobileSubSubMenu.slideUp(); // Close all sub-submenus on first load

    $mobileSubSubMenuToggle.on('click', function() {
        $mobileSubSubMenu.stop().slideUp();
        var $targetMenuItem = $(this).closest('.js-mobile-sub-menu-item');
        var $targetSubSubMenu = $targetMenuItem.find('.js-mobile-sub-sub-menu');
        var activeClassName = 'is-open';

        if ($(this).hasClass(activeClassName)) { // If clicked sub-submenu is active
            $mobileSubSubMenuToggle.removeClass(activeClassName);
            $targetSubSubMenu.stop().slideUp();
        } else { // If clicked sub-submenu is not active
            $mobileSubSubMenuToggle.removeClass(activeClassName);
            $(this).addClass(activeClassName);
            $targetSubSubMenu.stop().slideDown();
        }
    });

    $sideMenu.slideUp(); // Close mob side-menu on first load

    $toggleSideMenu.on('click', function() {
        if (!$(this).hasClass('active')) {
            $(this).addClass('active');
            $sideMenu.stop().slideDown();
        } else {
            $(this).removeClass('active');
            $sideMenu.stop().slideUp();
        }
    });

    $contactPanelButton.on('click', function(e) {

        var isActiveClassName = 'is-open';
        var $contactPanel = $(this).closest('.js-contact-panel');

        if (!$contactPanel.hasClass(isActiveClassName)) {
            e.preventDefault();
            $contactPanel.addClass(isActiveClassName);
        }
    });
});

function isMobile() {
    return window.matchMedia("only screen and (max-width:" + 992 + "px)").matches;
}
