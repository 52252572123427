$(document).ready(function($) {

    cssVars({
        // ------Targets------

        // Root element containing <link> and <style> elements to process
        rootElement   : document, // Default: document

        // Determines if shadow DOM tree(s) nested within the options.rootElement will be processed.
        shadowDOM     : false, // Default: false


        // ------Sources------

        /* CSS selector matching <link> and <style> elements to process.

        Include only CSS stylesheet with ID: assupport-template-css */
        include       : 'link[id=assupport-template-css],style', // Default: link[rel=stylesheet],style

        // CSS selector matching <link rel="stylesheet"> and <style> elements to exclude from those matched by options.include.
        exclude       : '', // Default: none

        // https://jhildenbiddle.github.io/css-vars-ponyfill/#/?id=variables
        variables     : {}, // Default {}


        // ------Options------

        /* When true, the ponyfill will only transform CSS and trigger callbacks in browsers that lack
        native support for CSS custom properties. When false, the ponyfill will transform CSS and trigger
        callbacks in all browsers, regardless of their support for CSS custom properties. */
        onlyLegacy    : true, // Default: true

        // https://jhildenbiddle.github.io/css-vars-ponyfill/#/?id=preservestatic
        preserveStatic: true, // Default: true

        // https://jhildenbiddle.github.io/css-vars-ponyfill/#/?id=preservevars
        preserveVars  : false, // Default: false

        // Determines if warning and error messages will be displayed in the console.
        silent        : false, // Default: false

        // https://jhildenbiddle.github.io/css-vars-ponyfill/#/?id=updatedom
        updateDOM     : true, // Default: true

        // Determines if the ponyfill will convert relative url() paths to absolute urls.
        updateURLs    : true, // Default: true

        // https://jhildenbiddle.github.io/css-vars-ponyfill/#/?id=watch
        watch         : false, // Default: false

        // Callbacks
        onBeforeSend: function(xhr, elm, url) {
            // ...
        },
        onWarning: function(message) {
            // ...
        },
        onError: function(message, elm, xhr, url) {
            // ...
        },
        onSuccess: function(cssText, elm, url) {
            // ...
        },
        onComplete: function(cssText, styleElms, cssVariables, benchmark) {
            // ...
        }
    });

});
